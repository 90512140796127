
export default {
  name: 'SectionContent',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: true,
    },
    imageMobile: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      required: true,
    },
    displayTriangle: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondParagraph: {
      type: Boolean,
      required: false,
      default: false,
    },
    thirdParagraph: {
      type: Boolean,
      required: false,
      default: false,
    },
    fourthParagraph: {
      type: Boolean,
      required: false,
      default: false,
    },
    fifthParagraph: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
