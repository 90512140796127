
export default {
  name: 'NewsPopup',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalBg() {
      return this.$colorMode.preference
    },
    modalText() {
      return this.$colorMode.preference === 'dark' ? 'light' : 'dark'
    },
  },
  methods: {
    closePopupModal() {
      this.$cookies.set('is-' + this.value.id, true, {
        maxAge: 60 * 60 * 24 * 31,
      })
      this.$bvModal.hide(this.value.id)
    },
  },
}
