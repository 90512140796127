
export default {
  name: 'PartnersCarousel',
  data() {
    return {
      mobileSlide: 0,
      desktopSlide: 0,
      mobileSliding: null,
      desktopSliding: null,
    }
  },
  methods: {
    onMobileSlideStart(slide) {
      this.mobileSliding = true
    },
    onMobileSlideEnd(slide) {
      this.mobileSliding = false
    },
    onDesktopSlideStart(slide) {
      this.desktopSliding = true
    },
    onDesktopSlideEnd(slide) {
      this.desktopSliding = false
    },
  },
}
