
export default {
  name: 'ButtonTo',
  props: {
    page: {
      type: String,
      required: true,
    },
    anchorText: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
}
